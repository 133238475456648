<template>
    <section class="main-section">
        <div class="container">
            <div class="row">
                <h1>Privacy Policy</h1>
                <h3>Who We Are and Scope of this Privacy Policy</h3>
                <p>
                    We are Fonio Inc., a U.S. company headquartered in Wymington, collectively referred to as "we" or
                    "Fonio" in this Privacy Policy. Our Software-as-a-Service products ("Services") are used to simplify
                    how people connect with each other and the world around them to drive meaningful interactions,
                    deepen relationships, and create better outcomes for professionals and businesses.
                </p>
                <p>
                    In this Privacy Policy, we explain what personal data we collect from visitors to the Fonio websites
                    and/or properties that link to this Privacy Policy and how we use such personal data.
                </p>
                <p>
                    It is important to note that this Privacy Policy does not apply to any other data, such as any
                    personal data that may be included in the files, documents, recordings, chat logs, transcripts, and
                    similar data that we maintain on our customers' behalf, as well as any other information our
                    customers may upload to their Fonio account(s) in connection with their use of our Services (which
                    we refer to as "Content" in our Terms of Service) or information gathered from other channels, such
                    as publicly available sources. For the avoidance of doubt, we process customer Content, including
                    any personal data which may be included therein, solely for the purpose of providing and operating
                    our Services to our customers and only in accordance with their written instructions, which
                    typically take the form of our Terms of Service, a Data Processing Addendum and/or any similar
                    written agreement between Fonio and our customer.
                </p>
                <h3>Data Categories and Collection Purposes</h3>
                <p>
                    When you visit our website(s) and/or use our Services, you provide the following categories of
                    personal data to us:
                </p>
                <ul>
                    <li>
                        Customer Account and Registration Data is data you provide when you create your account with us,
                        request support or technical assistance, or register for events, webinars, whitepapers and
                        surveys, which typically include first and last name, billing data, and a valid email address.
                        We need this data to provide the Services to you, to maintain and support your account, as well
                        as to collect payment.
                    </li>
                    <li>
                        Service Data (including Session, Location and Usage data):When you visit our websites and use
                        our Services, we receive data that you or others voluntarily enter, including on schedules and
                        attendee lists, as well as data that is automatically logged by the website or Service - for
                        example, duration of session, connections made, hardware, equipment and devices used, IP
                        addresses, location, language settings, operating system used, unique device identifiers and
                        other diagnostic data. We need this information to provide, operate, and improve our Services.
                        We collect location-based data for the purpose of providing, operating, and supporting the
                        Service and for fraud prevention and security monitoring; you can disable location data
                        transmission on mobile devices at any time by disabling location services from the settings menu
                        on your device.
                    </li>
                </ul>
                <p>
                    We strive to limit the types and categories of personal data that is collected from, and processed
                    on behalf of, our users to include only information which is necessary to achieve the purpose(s) for
                    which it was collected and we do not use personal data for additional purpose(s) which are
                    incompatible with their initial collection. In other words, we have measures and policies in place
                    designed to ensure that we only collect and process information from our users that we believe is
                    necessary to operate and provide them with a world-class Service.
                </p>
                <h3>How We Use Your Data</h3>
                <p>
                    We use the data we collect from visitors to our websites to: (a) provide and operate our Services;
                    (b) address and respond to service, security, and customer support needs; (c) detect, prevent, or
                    otherwise address fraud, security, unlawful, or technical issues; (d) comply with applicable laws
                    and administrative requests, protect our rights, assert and defend against claims; (e) fulfill
                    contracts; (f) maintain and improve our Services; (g) provide analysis or valuable data back to our
                    customers and users; (h) assess the needs of your business to determine and promote other Fonio
                    products which we believe may be helpful to you; (i) provide product updates, marketing
                    communications, and service data; (j) conduct research and analysis for business planning and
                    product development; (k) display content based upon your interests; and (l) to the extent permitted
                    by law, we may combine, correct, and enrich personal data that we receive from you with data about
                    you from other sources, including publicly available databases or from third parties to update,
                    expand, and analyze our records, identify new prospects for marketing, and provide products and
                    Services that may be of interest to you.
                </p>
                <h3>Analytics, Cookies and Other Web Site Technologies</h3>
                <p>
                    We continuously improve our websites and Services through the use of first- and third-party cookies
                    and other web analytics tools, which help us understand how our visitors use our websites, desktop
                    tools, and mobile applications, what webpages, features and functions they like and dislike, and
                    where they may have run into problems which need to be addressed.
                </p>
                <p>Google Analytics and Adobe Marketing Cloud</p>
                <p>
                    We use Google Analytics. You can prevent your data from being used by Google Analytics on our
                    websites by installing the Google Analytics opt-out browser add-on here. For enhanced privacy
                    purposes, we also employ IP address masking, a technique used to truncate IP addresses collected by
                    Google Analytics and store them in an abbreviated form to prevent them from being traced back to
                    individual users. Portions of our website may also use Google Analytics for Display Advertisers
                    including DoubleClick or Dynamic Remarketing which provide interest-based ads based on your visit to
                    this or other websites. You can use Ads Settings to manage the Google ads you see and opt-out of
                    interest-based ads.We also use Adobe Marketing Cloud as described here. You can similarly exercise
                    your rights with respect to use of this data as described in the "Exercising Choice" section below.
                </p>
                <p>
                    Social Media: Many of our websites include social media features, such as Facebook, Google, and
                    Twitter "share" buttons. If you use these features they may collect your IP address, which page you
                    are visiting on our site, and may set a cookie to enable the feature to function properly – you can
                    exercise your rights with respect to the use of this data as specified in the "Exercising Choice"
                    section below. These services will also authenticate your identity and provide you the option to
                    share certain personal data with us such as your name and email address to pre-populate our sign-up
                    form or provide feedback. Your interactions with these features are governed by the Privacy Policy
                    of the third-party company providing them.
                </p>
                <p>Exercising Choice</p>
                <p>
                    We do not currently respond to web browser "do not track" signals or similar mechanisms, but instead
                    offer visitors to our websites more information, choices, and control over cookies and other web
                    analytics tools via Fonio’s Cookie Consent Manager (available via the "Cookie Preferences" hyperlink
                    at the bottom of this page) and/or as specified below. Fonio’s Cookie Consent Manager provides
                    visitors with information about the types and categories of cookies we utilize and you may also
                    exercise your rights as follows.
                </p>
                <p>
                    You can still view our websites if you choose to set your browser to refuse all cookies; however,
                    you will need to keep certain cookies enabled to establish an account or to install the Services.
                </p>
                <h3>Security</h3>
                <p>
                    Fonio has implemented a comprehensive data privacy and security program which includes appropriate
                    technical and organizational measures designed to safeguard and protect the personal, identifiable,
                    and/or confidential information we collect or you share with us. Fonio’s operations, on a product
                    and/or suite-specific basis, have been assessed by independent third-party auditors against
                    recognized security standards and controls, including SOC2 Type II, BSI C5, SOC3, and ISO 27001.
                </p>
                <h3>Privacy Frameworks</h3>
                <p>
                    Fonio is headquartered in the United States of America. Information that we collect and maintain may
                    be transferred to, or controlled and processed in, the United States and/or other countries around
                    the world.
                </p>
                <p>Standard Contractual Clauses</p>
                <p>
                    Fonio offers a Data Processing Addendum ("DPA") which incorporates Standard Contractual Clauses
                    ("SCCs") for data transfers outside the European Union and European Economic Area. For more
                    information about Fonio’s program, as well as supplementary documentation to use in conjunction with
                    its DPA and SCCs.
                </p>
                <h3>Changes</h3>
                <p>
                    We update this Privacy Policy from time-to-time to reflect changes to our personal data handling
                    practices or respond to new legal requirements and will post updates here. However, if we make any
                    material changes that have a substantive and adverse impact on your privacy, we will provide notice
                    on this website and additionally notify you by email (sent to the e-mail address specified in your
                    account) for your approval prior to the change becoming effective. We encourage you to periodically
                    review this page for the latest information on our privacy practices.
                </p>
                <h3>Children's Privacy</h3>
                <p>
                    Fonio webpages are intended for general audiences – we do not seek through our sites to gather
                    personal data from or about persons that are sixteen (16) years of age or younger. If you inform us
                    or we otherwise become aware that we have unintentionally received personal data from an individual
                    under the age of sixteen (16), we will delete this information from our records.
                </p>
                <h3>Access Requests</h3>
                <p>
                    Fonio respects your control over your information and, upon request, we will confirm whether we hold
                    or are processing information that we have collected from you. You also have the right to amend or
                    update inaccurate or incomplete personal data, request deletion of your personal data, or request
                    that we no longer use it. Under certain circumstances we will not be able to fulfill your request,
                    such as if it interferes with our regulatory obligations, affects legal matters, we cannot verify
                    your identity, or it involves disproportionate cost or effort, but in any event we will respond to
                    your request within thirty (30) days and provide you an explanation.
                </p>
                <p>
                    Please note that for personal data about you that we have obtained or received for processing on
                    behalf of a separate, unaffiliated entity – which determined the means and purposes of processing,
                    all such requests should be made to that entity directly. We will honor and support any instructions
                    they provide us with respect to your personal data.
                </p>
                <h3>Contact Us</h3>
                <p>
                    If you have questions or requests relating to personal data or privacy, please email us at legal
                    [at] fonio.app.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
</script>

<style></style>
