import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/pages/HomePage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import TermsOfUsePage from './components/pages/TermsOfUsePage'

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/PrivacyPolicyPage',
        component: PrivacyPolicyPage,
    },
    {
        path: '/TermsOfUsePage',
        component: TermsOfUsePage,
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: '/',
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    },
})

export default router
