<template>
    <section class="main-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <h1 class="mb-48 title-big-m">
                        Transform your corporate image with our professional business phone system
                    </h1>
                    <div class="d-flex mb-48 mr-mobile-zero d-block-mobile align-items-center">
                        <!-- <div class="mr-17 mb-m-12 text-center-m">
                            <img :src="joiners" class="joiners-img" alt="Your business phone system" />
                        </div> -->
                        <div class="text-center-m">
                            <p class="m-0">
                                — and the best part? It's completely FREE.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex jcc-m gap-md-4 gap-2 main-section-buttons">
                        <a href="https://portal.fonio.app/#/registration" class="indigo-btn">Get Started</a>
                        <a href="#contact_section" class="ghost-btn">Contact Us</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 image-main-container">
                    <img class="image-main" :src="imageMain" alt="Entrepreneurs and start impressing your customers" />
                    <img class="image-mobile" :src="imageMainMobile" alt="Entrepreneurs and start impressing your customers" />
                </div>
            </div>
        </div>
    </section>
    <section id="whyus_section" style="margin:0;height:1px"></section>
    <section class="solution-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <div class="pr-72 pr-m-0">
                        <div class="indigo-text text-center-m">The all-in-one solution</div>
                        <div class="title title-big-m mb-40">Why you should choose fonio?</div>
                        <div class="choseFontio__img-block">
                            <img :src="choseFontio" class="choseFontio__img show-only-tablet-and-mobile img-fluid" alt="Why You Should Choose Fonio?" />
                        </div>
                        <div class="text-center-m">
                            <p>
                                Impress your customers and prospects with a phone system that is designed to make your
                                business sound and operate like a larger enterprise. Give your business the edge it
                                needs to compete in today's market with our Fonio's SaaS phone platform.
                            </p>
                        </div>
                        <div class="ul-wrapper">
                            <ul class="mb-m-32">
                                <li><img :src="features_01" alt="Free Signup" /> Free Signup</li>
                                <li><img :src="features_02" alt="Unlimited Numers" /> Unlimited Numers</li>
                                <li><img :src="features_03" alt="National Footprint" /> National Footprint</li>
                            </ul>
                        </div>
                        <div class="text-center-m">
                            <a href="https://portal.fonio.app/#/registration" class="mb-m-32 ghost-btn try-for-free">Try for Free</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <img :src="choseFontio" class="choseFontio__img_pc img-fluid hide-mobile" alt="phone system" />
                </div>
            </div>
        </div>
        <div class="ecipse-bg"></div>
    </section>
    <section id="ourservice_section" style="margin:0;height:1px"></section>
    <section class="price-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6">
                    <div class="title title-big-m mb-48">
                        Simple Pricing with Everything <span class="indigo-color">Unlimited</span>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6 text-right">
                    <a href="https://portal.fonio.app/#/registration" class="indigo-btn ourservice_section-start float-right mt-80 mr-25">
                        Get Started
                    </a>
                </div>
            </div>
            <div class="row ourservice_section-row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="ourservice_section-grid">
                        <card>
                            <img :src="unlimited_01" alt="Unlimited Numbers" />
                            <div class="price-section-car-title">Unlimited Numbers</div>
                        </card>
                        <card>
                            <img :src="unlimited_02" alt="Unlimited Users" />
                            <div class="price-section-car-title">Unlimited Users</div>
                        </card>
                        <card>
                            <img :src="unlimited_03" alt="Unlimited Extension" />
                            <div class="price-section-car-title">Unlimited Extension</div>
                        </card>
                        <card>
                            <img :src="unlimited_04" alt="Unlimited Call Minutes" />
                            <div class="price-section-car-title">Unlimited Call Minutes</div>
                        </card>
                        <card>
                            <img :src="unlimited_05" alt="Unlimited SMS" />
                            <div class="price-section-car-title">Unlimited SMS</div>
                        </card>
                        <card>
                            <img :src="unlimited_06" alt="Unlimited Connections" />
                            <div class="price-section-car-title">Unlimited Connections</div>
                        </card>
                        <card>
                            <img :src="unlimited_07" alt="Unlimited Callflow" />
                            <div class="price-section-car-title">Unlimited Callflow</div>
                        </card>
                        <card>
                            <img :src="unlimited_08" alt="Unlimited Storage" />
                            <div class="price-section-car-title">Unlimited Storage</div>
                        </card>
                    </div>
                </div>
                <div class="show-only-tablet-and-mobile text-center">
                    <a href="https://portal.fonio.app/#/registration" class="indigo-btn get-started mt-64">Get Started</a>
                </div>
            </div>
        </div>
    </section>

    <section class="slide-section">
        <div class="row">
            <div class="text-center">
                <div class="indigo-text">Our services</div>
                <div class="title title-m mb-72">Deliver a spectacular <br/> user-friendly UCaaS platform</div>
            </div>
            <div>
                <Flicking
                    :options="{
                        initialIndex: 1,
                        moveType: 'strict',
                        gap: '16px',
                        circular: true,
                        prevNextButtons: false,
                        pageDots: false,
                    }"
                    :plugins="plugins"
                >
                    <div key="slide1" class="panel slider-card slider-card--indigo">
                        <div>
                            <div class="d-flex">
                                <div class="flex-grow-1 hide-mobile">
                                    <img :src="slider_01" class="slider-image" alt="Flexible Call Flow Configuration" />
                                </div>
                                <div class="flex-grow-1 pl-m-0 pl-104">
                                    <div class="indigo-text pt-56 pt-m-0">Feature 1</div>
                                    <div class="smallTitle slider-title-m mb-32">Flexible Call Flow Configuration</div>
                                    <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                        <img :src="slider_01" class="slider-image" alt="Flexible Call Flow Configuration" />
                                    </div>
                                    <p class="mb-108 mb-m-12">
                                        You can design how to handle your inbound calls in an interactive manner. Let
                                        your client feel the professionalism conveyed via your interactive voice
                                        response.
                                    </p>
                                    <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="slide2" class="panel slider-card slider-card--light-indigo">
                        <div>
                            <div class="d-flex">
                                <div class="flex-grow-1 hide-mobile">
                                    <img :src="slider_02" class="slider-image" alt="Auto Transcription on Voicemail" />
                                </div>
                                <div class="flex-grow-1 pl-m-0 pl-104">
                                    <div class="indigo-text pt-56 pt-m-0">Feature 2</div>
                                    <div class="smallTitle slider-title-m mb-32">Auto Transcription in Voicemails</div>
                                    <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                        <img :src="slider_02" class="slider-image" alt="Auto Transcription on Voicemail" />
                                    </div>
                                    <p class="mb-108 mb-m-12">
                                        We automatically transcript your inbound voicemails and deliver it to you via email,
                                        so you won’t miss a single call.
                                    </p>
                                    <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="slide3" class="panel slider-card slider-card--dark-blue">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_03" class="slider-image" alt="Never Missed a Call or Text" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 3</div>
                                <div class="smallTitle slider-title-m mb-32">Never Miss a Call or Text</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_03" class="slider-image" alt="Never Missed a Call or Text" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    We automatically pushed any missed calls and unread text messages to your emails, as
                                    well as presenting them in your portal, so you won’t miss any important messages from
                                    customers.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide4" class="panel slider-card slider-card--orange-light">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_04" class="slider-image" alt="Multi-level Interactive Voice Recording" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 4</div>
                                <div class="smallTitle slider-title-m mb-32">
                                    Multi-level Interactive Voice Recording
                                </div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_04" class="slider-image" alt="Multi-level Interactive Voice Recording" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    You can easily build a multi-level interactive voice menu selection to guide your
                                    customers to the right place. Voice prompts are easily customized and we can even 
                                    create them for you using our state-of-the-art AI voiceover.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide5" class="panel slider-card slider-card--blue-light">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_05" class="slider-image" alt="Time Sensitive Call Flow" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 5</div>
                                <div class="smallTitle slider-title-m mb-32">Time Sensitive Call Flow</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_05" class="slider-image" alt="Time Sensitive Call Flow" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    Fonio lets you easily configure your inbound call handling based on the day of the
                                    week and time of day. You don’t annoy your customer with a dumb IVR prompt anymore,
                                    even during a holiday.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide6" class="panel slider-card slider-card--red-light">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_06" class="slider-image" alt="Geo Sensitive Call Handling" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 6</div>
                                <div class="smallTitle slider-title-m mb-32">Geo Sensitive Call Handling</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_06" class="slider-image" alt="Geo Sensitive Call Handling" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    Large brands have different agents for customers in different geographical
                                    areas. You want to convey this kind of big company image to your prospects by giving
                                    them tailor-made services that can impress them.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide7" class="panel slider-card slider-card--salat">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_07" class="slider-image" alt="Public and Private mailboxing" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 7</div>
                                <div class="smallTitle slider-title-m mb-32">Public and Private mailboxing</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_07" class="slider-image" alt="Public and Private mailboxing" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    You get to create as many mailboxes as you like and mark them as public or private.
                                    For a public mailbox, you can have multiple staff members share the handling of voicemails
                                    together.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide8" class="panel slider-card slider-card--blue">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_08" class="slider-image" alt="Live Calls Monitoring" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 8</div>
                                <div class="smallTitle slider-title-m mb-32">Live Calls Monitoring</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_08" class="slider-image" alt="Live Calls Monitoring" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    You get to create as many mailboxes as you like and mark it as public or private.
                                    For public mailbox, you can have multiple staff to share the handling of voicemails
                                    together.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide9" class="panel slider-card slider-card--green">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_09" class="slider-image" alt="Call Back Manager" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 9</div>
                                <div class="smallTitle slider-title-m mb-32">Call Back Manager</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_09" class="slider-image" alt="Call Back Manager" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    Let your team know who has requested a callback and notify your staff to
                                    callback when fonio sees that they are available again. That way, you never miss a
                                    sales opportunity.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div key="slide10" class="panel slider-card slider-card--orange">
                        <div class="d-flex">
                            <div class="flex-grow-1 hide-mobile">
                                <img :src="slider_10" class="slider-image" alt="Easy Call Forwarding" />
                            </div>
                            <div class="flex-grow-1 pl-m-0 pl-104">
                                <div class="indigo-text pt-56 pt-m-0">Feature 10</div>
                                <div class="smallTitle slider-title-m mb-32">Easy Call Forwarding</div>
                                <div class="flex-grow-1 show-only-tablet-and-mobile mb-24">
                                    <img :src="slider_10" class="slider-image" alt="Easy Call Forwarding" />
                                </div>
                                <p class="mb-108 mb-m-12">
                                    You can route calls to another phone number or another SIP URL easily. You can
                                    forward a single number or multiple numbers in a round robin or parallel manner. It
                                    is all under your control.
                                </p>
                                <a href="https://portal.fonio.app/#/registration" class="black-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <template #viewport>
                        <span class="flicking-arrow-prev">
                            <img :src="arrowLeft" alt="<" />
                        </span>
                        <span class="flicking-arrow-next">
                            <img :src="arrowRight" alt=">" />
                        </span>
                    </template>
                </Flicking>
            </div>
        </div>
    </section>
    <section id="pricing_section" style="margin:0;height:1px"></section>
    <section class="bill-section">
        <div class="container">
            <div class="row mx-0">
                <div class="col-lg-4 col-md-12 col-xs-12 mb-4 mb-lg-0">
                    <div class="indigo-text">Pricing</div>
                    <div class="title title-big-m mb-50">We give you the best price</div>
                    <div class="login-register-block-radios form-group mb-31 d-flex align-items-center">
                        <input
                            type="radio"
                            class="btn-check"
                            name="options-outlined"
                            id="success-outlined"
                            autocomplete="off"
                            checked
                        />
                        <label @click="changePeriod(true)" class="btn btn-gray text-center flex-fill" for="success-outlined"
                            >Per month</label
                        >
                        <input
                            type="radio"
                            class="btn-check"
                            name="options-outlined"
                            id="dark-outlined"
                            autocomplete="off"
                        />
                        <label @click="changePeriod(false)" class="btn btn-gray text-center flex-fill" for="dark-outlined"
                            >Per year</label
                        >
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-xs-12" style="padding-inline:24px">
                    <div class="d-flex d-flex-t d-block-mobile">
                        <div class="price-card flex-grow-1 mr-32 mr-mobile-zero">
                            <div class="text-center">
                                <img class="price-card--image" :src="priceCrad_01" alt="free" />
                                <div class="price-card--title">Free</div>
                            </div>
                            <div class="text-center my-2 grey--text">$0</div>
                            <div class="d-flex justify-content-between borderBottom">
                                <ul>
                                    <li>1 Free Number</li>
                                    <li>Unlimited Inbound Calls</li>
                                    <li>{{ pricing.outbound_call_rate }} / min Outbound Calls</li>
                                </ul>
                            </div>
                            <div class="text-center mt-m-30 mt-160 mb-m-16">
                                <a
                                    href="https://portal.fonio.app/#/registration"
                                    class="long-btn price-btn w-100"
                                    >Get Started</a
                                >
                            </div>
                        </div>
                        <div class="price-card flex-grow-1">
                            <div class="text-center">
                                <img class="price-card--image" :src="priceCrad_02" alt="Professional" />
                                <div class="price-card--title">Professional</div>
                            </div>
                            <div v-if="period === 'monthly'" class="text-center my-2 grey--text">{{ formatPrice(pricing.local_number_monthly_mrc) }} / month</div>
                            <div v-else class="text-center my-2 grey--text">{{ formatPrice(pricing.local_number_yearly_mrc) }} / year</div>
                            <div class="d-flex justify-content-between borderBottom">
                                <div>
                                    <ul>
                                        <!-- <li v-if="period === 'monthly'">
                                            {{ formatPrice(pricing.local_number_monthly_mrc) }}
                                            / Number / mo
                                        </li>
                                        <li v-else>
                                            {{ formatPrice(pricing.local_number_yearly_mrc) }}
                                            / Number / yr
                                        </li> -->
                                        <li>Unlimited US/Canada Numbers</li>
                                        <li>{{ pricing.local_number_inbound_rate }} / min Inbound Calls</li>
                                        <li>{{ pricing.outbound_call_rate }} / min Outbound Calls</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="text-center mt-m-30 mt-160 mb-m-16">
                                <a
                                    href="https://portal.fonio.app/#/registration?paid=true"
                                    style="
                                        border: 0;
                                        background: #eef2ff;
                                        color: #4338ca;
                                        font-family: 'Plus Jakarta Sans';
                                        font-size: 18px;
                                    "
                                    class="long-btn price-btn w-100"
                                    >Get Started</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contact_section" class="contact-section">
        <div class="container">
            <div class="row">
                <div class="contact-card">
                    <div class="indigo-text">Have any questions?</div>
                    <h1 class="mb-56">Contact Us</h1>
                    <div class="d-flex d-block-mobile">
                        <div class="flex-grow-1 mr-24 mr-mobile-zero">
                            <input :maxlength="20" type="text" placeholder="First Name" v-model="firstName" required />
                        </div>
                        <div class="flex-grow-1">
                            <input :maxlength="20" type="text" placeholder="Second Name" v-model="secondName" required />
                        </div>
                    </div>
                    <div>
                        <input type="text" placeholder="Email" v-model="email" required />
                    </div>
                    <div>
                        <textarea placeholder="Enter Your Message..." class="mb-56" v-model="message" required>
Enter Your Message...</textarea
                        >
                    </div>
                    <div v-if="errors" class="mb-24 text-danger">
                        {{ errors }}
                    </div>
                    <div v-if="success" class="mb-24 text-success">
                        {{ success }}
                    </div>
                    <button :disabled="disableContactSubmit" class="indigo-btn" @click="checkForm">Send Message</button>
                </div>
            </div>
        </div>
        <div class="ecipse-bg-2"></div>
    </section>
    <section id="virtualphonesystem_section" style="margin:0;height:1px"></section>
    <section class="virtual_phone_system_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img :src="vitualPhone" alt="About" class="img-fluid hide-mobile" />
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="pl-72 pr-l-0">
                        <div class="indigo-text text-center-m">About us</div>
                        <div class="title title-big-m mb-40">
                            Fonio Telephony Platform for Small and Medium Size Enterprises
                        </div>
                        <div>
                            <img :src="vitualPhone" alt="About" class="img-fluid show-only-mobile" />
                        </div>
                        <p>
                            Fonio’s nation-wide networks let you order phone numbers for the entire United States. We
                            interconnect directly with CLECs and ILECs in the countries to ensure all your calls are
                            100% connected.
                        </p>
                        <p>
                            Fonio is bundled with a bunch of powerful features that are normally available to large
                            companies. Imagine how you can impress your customers with Fonio.
                        </p>
                        <div class="text-center-m">
                            <a href="https://portal.fonio.app/#/registration" class="ghost-btn mt-40">Try for Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="faq_section" style="margin:0;height:1px"></section>
    <section class="faq_section">
        <div class="container">
            <div class="row">
                <div class="text-center">
                    <div class="indigo-text">FAQ</div>
                    <div class="title title-big-m mb-72">Popular Questions</div>
                </div>
                <div class="accordion-container">
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('1')"
                        accordionNumber="1"
                        textQuestion="Can I port my existing number to Fonio?"
                        textDescription="Yes, you can port your existing number to Fonio and it will work exactly like what you have now."
                    />
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('2')"
                        accordionNumber="2"
                        textQuestion="Can I have more than one numbers?"
                        textDescription="Yes, you can have as many numbers as you want."
                    />
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('3')"
                        accordionNumber="3"
                        textQuestion="What payment method is supported?"
                        textDescription="Fonio supports Credit Card."
                    />
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('4')"
                        accordionNumber="4"
                        textQuestion="How can I get support?"
                        textDescription="You can log your ticket to our support system or contact live support."
                    />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Accordion from './../helpers/Accordion.vue'
import Flicking from '@egjs/vue3-flicking'
import { Arrow } from '@egjs/flicking-plugins'
import axios from 'axios'
import { api_public_pricing } from '@/components/services/api'
import { BASE_URL } from '@/components/services/config'
import countries from '@/data/countries.json'

export default {
    components: {
        Accordion,
        Flicking,
    },
    data() {
        return {
            countries,
            period: 'monthly',
            pricing: [],

            firstName: '',
            secondName: '',
            email: '',
            message: '',

            isActiveTab: 1,
            isActiveAccordion: 0,

            errors: '',
            success: '',

            vitualPhone: require('./../../assets/images/vitual-phone.jpg'),
            choseFontio: require('./../../assets/images/chose-fonio.jpg'),
            pricingCard: require('./../../assets/images/pricing-card.jpg'),
            joiners: require('./../../assets/images/joiners.jpg'),

            unlimited_01: require('./../../assets/images/unlimited_01.svg'),
            unlimited_02: require('./../../assets/images/unlimited_02.svg'),
            unlimited_03: require('./../../assets/images/unlimited_03.svg'),
            unlimited_04: require('./../../assets/images/unlimited_04.svg'),
            unlimited_05: require('./../../assets/images/unlimited_05.svg'),
            unlimited_06: require('./../../assets/images/unlimited_06.svg'),
            unlimited_07: require('./../../assets/images/unlimited_07.svg'),
            unlimited_08: require('./../../assets/images/unlimited_08.svg'),

            features_01: require('./../../assets/images/features_01.svg'),
            features_02: require('./../../assets/images/features_02.svg'),
            features_03: require('./../../assets/images/features_03.svg'),

            slider_01: require('./../../assets/images/slide_01.jpg'),
            slider_02: require('./../../assets/images/slide_02.jpg'),
            slider_03: require('./../../assets/images/slide_03.jpg'),
            slider_04: require('./../../assets/images/slide_04.jpg'),
            slider_05: require('./../../assets/images/slide_05.jpg'),
            slider_06: require('./../../assets/images/slide_06.jpg'),
            slider_07: require('./../../assets/images/slide_07.jpg'),
            slider_08: require('./../../assets/images/slide_08.jpg'),
            slider_09: require('./../../assets/images/slide_09.jpg'),
            slider_10: require('./../../assets/images/slide_10.jpg'),

            priceCrad_01: require('./../../assets/images/priceCrad_01.svg'),
            priceCrad_02: require('./../../assets/images/priceCrad_02.svg'),

            ticksquare: require('./../../assets/images/ticksquare.svg'),

            imageMain: require('./../../assets/images/main.jpg'),
            imageMainMobile: require('./../../assets/images/main-mobile.jpg'),
            arrowLeft: require('./../../assets/images/arrow-left-slider.svg'),
            arrowRight: require('./../../assets/images/arrow-right-slider.svg'),
            plugins: [new Arrow()]
        }
    },
    computed: {
        disableContactSubmit () {
            return !this.firstName.trim() || !this.secondName.trim() ||
                !this.email.trim() || !this.message.trim()
        }
    },
    created() {
        this.getPricing()
    },
    methods: {
        formatPrice(price) {
          return ['', undefined, null, '-'].includes(price) ? 'FREE' : `$${price}`
        },
        async getPricing() {
            try {
                const { data: res } = await axios.get(BASE_URL + api_public_pricing)
                this.pricing = res[0]
            } catch (e) {
                console.log(e)
            }
        },
        changePeriod(value) {
            value === false ? (this.period = 'yearly') : (this.period = 'monthly')
        },
        isValidEmail(email) {
            console.log(/^[^@]+@\w+(\.\w+)+\w$/.test(email) ? true : false)
            return /^[^@]+@\w+(\.\w+)+\w$/.test(email) ? true : false
        },
        checkForm() {
            if (this.firstName.length < 2) {
                this.errors = 'First Name required.'
                return false
            }
            if (this.secondName.length < 2) {
                this.errors = 'Second name required.'
                return false
            }
            if (this.isValidEmail(this.email) === false) {
                this.errors = 'Email invalid.'
                return false
            }
            if (this.email.length < 2) {
                this.errors = 'Email required.'
                return false
            }
            if (this.message.length < 2) {
                this.errors = 'Message required.'
                return false
            }

            this.sendMessage()
        },
        sendMessage() {
            const headers = { Accept: 'text/plain', 'Content-Type': 'text/plain' }

            axios
                .post(
                    'https://mattermost.denovolab.com/hooks/fx3oo6dqsfn3mef8fekd89fb9e',
                    {
                        text: `Fonio contact \n firstName: ${this.firstName} \n secondName: ${this.secondName} \n email: ${this.email} \n message: ${this.message}`,
                        channel: '#website-contact-form',
                        username: 'Peeringhub Subscriber',
                    },
                    headers
                )
                .then(() => {
                    this.firstName = ''
                    this.secondName = ''
                    this.email = ''
                    this.message = ''
                    this.errors = ''

                    this.success = 'The message was sent successfully'
                })
                .catch((error) => {
                    alert(error, error.response)
                })
        },
        setActiveTabId(number) {
            this.isActiveTab = number
        },
        setActiveAccordionId(number) {
            this.isActiveAccordion = number
        },
        setActiveButton(value) {
            this.showButton = value
        },
    },
}
</script>
<style>
@import './../assets/styles/pages/HomePage.css';
</style>
