<template>
    <footer class="d-md-none d-lg-none footerMobile">
        <div class="container">
            <div class="row footer-mobile-wrapper">
                <div class="text-center mb-32">
                    <img :src="logo" alt="" />
                </div>
                <div>
                    <div v-on:click="setItemFooter1()" class="accordion">
                        <div :class="accordionShow1 ? 'accordion-upArrow' : 'accordion-downArrow'"></div>
                        <div class="menuTitle">Company</div>
                        <div v-if="accordionShow1" class="accordion-body">
                            <ul>
                                <li>
                                    <a href="#whyus_section">Why us</a>
                                </li>
                                <li>
                                    <a href="#pricing_section">Pricing</a>
                                </li>
                                <li>
                                    <a href="#virtualphonesystem_section">Virtual Phone System</a>
                                </li>
                                <li>
                                    <a href="#faq_section">FAQ</a>
                                </li>
                                <li>
                                    <a href="#ourservice_section">Our Service</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div v-on:click="setItemFooter2()" class="accordion">
                        <div :class="accordionShow2 ? 'accordion-upArrow' : 'accordion-downArrow'"></div>
                        <div class="menuTitle">Legal</div>
                        <div v-if="accordionShow2" class="accordion-body">
                            <ul>
                                <li>
                                    <router-link to="/PrivacyPolicyPage"> Privacy Policy </router-link>
                                </li>
                                <li>
                                    <router-link to="/TermsOfUsePage"> Terms of Use </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div v-on:click="setItemFooter3()" class="accordion">
                        <div :class="accordionShow3 ? 'accordion-upArrow' : 'accordion-downArrow'"></div>
                        <div class="menuTitle">Contact</div>
                        <div v-if="accordionShow3" class="accordion-body">
                            <ul>
                                <li class="phone-link">(270) 555-0117</li>
                                <li>support@fonio.app</li>
                                <li>2118 Thornridge Cir. Syracuse, Connecticut 35624</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mb-24">
                        <div class="menuTitle text-center">Ready to Join?</div>
                    </div>
                    <div class="d-flex justify-content-between footer-buttons-wrapper">
                        <div>
                            <a href="https://portal.fonio.app" class="indigo-btn w-100 mr-24">Get Started</a>
                        </div>
                        <div>
                            <a href="#contact_section" class="ghost-btn w-100">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="d-flex justify-content-between">
                    <div>© {{ currentYear }} Fonio. All rights reserved.</div>
                    <div>
                        <!-- <a href="#">Twitter</a>
                        <a href="#">LinkedIn</a> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            accordionShow1: '',
            accordionShow2: '',
            accordionShow3: '',
            logo: require('./../../assets/images/fonio-logo.svg'),
        }
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    },
    methods: {
        setItemFooter1() {
            this.accordionShow1 = !this.accordionShow1
            this.accordionShow2 = false
            this.accordionShow3 = false
        },
        setItemFooter2() {
            this.accordionShow1 = false
            this.accordionShow2 = !this.accordionShow2
            this.accordionShow3 = false
        },
        setItemFooter3() {
            this.accordionShow1 = false
            this.accordionShow2 = false
            this.accordionShow3 = !this.accordionShow3
        },
    },
}
</script>
<style>
@import './../assets/styles/layouts/footer.css';
</style>
