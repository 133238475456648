<template>
    <section class="main-section">
        <div class="container">
            <div class="row">
                <h1>Terms of Service</h1>
                <p>
                    This is a legal agreement between the person or organization (“Customer” or “you”) agreeing to these
                    Terms of Service (“Terms”) and the applicable contracting entities at https://www.fonio.app
                    (“Fonio,” “us,” or “we”). By accepting these Terms, signing an Order, or using the Services, you
                    represent that you are of legal age and have the authority to bind the Customer to: (i) the Order;
                    (ii) these Terms; (iii) the "Service Descriptions" available at https://www.fonio.app, in each case,
                    as applicable (collectively the “Agreement”).
                </p>
                <h3>1. ACCESS AND USE OF THE SERVICES.</h3>
                <p>
                    1.1. Right to Use Services. You agree to use the Services in accordance with the use levels by which
                    we measure, price, and offer our Services as posted on our websites, your Order, or the Service
                    Descriptions (“Use Levels”). You may use our Services only as permitted in these Terms, and you
                    acknowledge our Privacy Policy at https://www.fonio.app/legal/privacy, which is incorporated by
                    reference. We grant you a limited right to use our Services only for business and professional
                    purposes. Technical support for the Services is described in the Service Descriptions. If your
                    affiliates use our Services, you warrant that you have the authority to bind those affiliates and
                    you will be liable if your affiliates do not comply with the Agreement. “Service(s)” means our
                    software-as-a-service offerings and our audio services (including any related hardware, which are
                    offered by Fonio Inc., or their subsidiaries, our telecommunications providers responsible for the
                    rates and terms relating to the respective audio services). The Service Descriptions are
                    incorporated into these Terms. You understand that your personal data may be processed in connection
                    with your use of our Services, software, and websites which are provided via equipment and resources
                    located in the United States and other locations throughout the world.
                </p>
                <p>
                    1.2.Limitations on Use. By using our Services, you agree on behalf of yourself, your users and your
                    attendees, not to (i) modify, prepare derivative works of, or reverse engineer, our Services; (ii)
                    knowingly or negligently use our Services in a way that abuses or disrupts our networks, user
                    accounts, or the Services; (iii) transmit through the Services any harassing, indecent, obscene, or
                    unlawful material; (iv) market, or resell the Services to any third party; (v) use the Services in
                    violation of applicable laws, or regulations; (vi) use the Services to send unauthorized
                    advertising, or spam; (vii) harvest, collect, or gather user data without their consent; (viii)
                    transmit through the Services any material that may infringe the intellectual property, privacy, or
                    other rights of third parties; or (ix) use the Services to commit fraud or impersonate any person or
                    entity.
                </p>
                <p>
                    1.3. Changes to Services. We reserve the right to enhance, upgrade, improve, or modify features of
                    our Services as we deem appropriate and in our discretion. We will not materially reduce the core
                    functionality (as set forth in the Service Descriptions) or discontinue any Services unless we
                    provide you with prior written notice. We may offer additional functionality to our standard
                    Services or premium feature improvements for an additional cost.
                </p>
                <p>
                    1.4. Proprietary Rights and Fonio Marks. You acknowledge that we or our licensors retain all
                    proprietary right, title and interest in the Services, our name, logo or other marks (together, the
                    “Fonio Marks”), and any related intellectual property rights, including, without limitation, all
                    modifications, enhancements, derivative works, and upgrades thereto. You agree that you will not use
                    or register any trademark, service mark, business name, domain name or social media account name or
                    handle which incorporates in whole or in part the Fonio Marks or is similar to any of these.
                </p>
                <h3>2. ORDERS, FEES AND PAYMENT.</h3>
                <p>
                    2.1. Orders. You may order Services using our then-current ordering processes (“Order”). All Orders
                    are effective on the earlier of (i) the date you submit your Order, or (ii) the date on the
                    signature block of the Order (“Effective Date”). Acceptance of your Order may be subject to our
                    verification and credit approval process. Each Order shall be treated as a separate and independent
                    Order. A Purchase Order is required for non-credit card transactions over 25,000 USD, or equivalent,
                    unless Customer does not require a Purchase Order as part of its purchasing process.
                </p>
                <p>
                    2.2. Fees and Payment. You agree to pay all applicable, undisputed fees for the Services on the
                    terms set forth in this Agreement or your invoice. Except as set forth in Section 3.3 below or in
                    the Service Descriptions, any and all payments you make to us for access to the Services are final
                    and non-refundable. You are responsible for all fees and charges you incur to your other service
                    providers (e.g. your broadband and internet provider) in connection with your use of the Services.
                    You are responsible for providing accurate and current billing, contact and payment information to
                    us or any reseller. You agree that we may charge your payment card or bill you for all amounts due
                    for your use of the Services, and we may take steps to update your payment card information (where
                    permitted) to ensure that payment can be processed. You agree that your credit card information and
                    related personal data may be provided to third parties for payment processing and fraud prevention
                    purposes. We may, where permitted by applicable law, suspend or terminate your Services if at any
                    time we determine that your payment information is inaccurate or not current, and you are
                    responsible for fees and overdraft charges that we may incur when we charge your card for payment.
                    We will not agree to submit invoices via any customer procure-to-pay online portal or Electronic
                    Data Interchange (EDI) portals. We reserve the right to update the price for Services at any time
                    after your Initial Term, and price changes will be effective as of your next billing cycle. In
                    accordance with applicable law, we will notify you in a timely manner of any price changes by
                    publishing on our website, emailing, quoting, or invoicing you.
                </p>
                <p>
                    2.3. Sales, Promotional Offers, Coupons and Pricing. Sales, promotions and other special discounted
                    pricing offers are temporary and, upon the renewal of your subscription, any such discounted pricing
                    offers may expire. We reserve the right to discontinue or modify any coupons, credits, sales, and
                    special promotional offers in our sole discretion.
                </p>
                <p>
                    2.4. Disputes; Delinquent Accounts. You must notify us of any fee dispute within 15 days of the
                    invoice date, and once resolved, you agree to pay those fees within 15 days. We may, on notice to
                    you, suspend or terminate your Services if you do not pay undisputed fees, and you agree to
                    reimburse us for all reasonable costs and expenses incurred in collecting undisputed delinquent
                    amounts.
                </p>
                <p>
                    2.5. Taxes and Withholding. You are responsible for all applicable sales, services, value-added,
                    goods and services, withholding, tariffs, Universal Services Fund (USF) fees or any other similar
                    fees as may be applicable in the location in which the Services are being provided (if applicable to
                    the Audio Services only) and similar taxes or fees (collectively, “Taxes and Fees”) imposed by any
                    government entity or collecting agency based on the Services, except those Taxes and Fees based on
                    our net income, or Taxes and Fees for which you have provided an exemption certificate. In all
                    cases, you will pay the amounts due under this Agreement to us in full without any right of set-off
                    or deduction.
                </p>
                <h3>3. TERM AND TERMINATION.</h3>
                <p>
                    3.1. Term. The initial term commitment for your purchase of Services will be as specified on an
                    Order (“Initial Term”) and begins on the Effective Date. After the Initial Term, the Services will,
                    unless otherwise specified in the Service Description for a particular Service, automatically renew
                    for additional 12-month periods (“Renewal Terms”), unless either party provides notice of
                    non-renewal at least 30 days before the current term expires. You may provide notice of non-renewal
                    for each Service you do not wish to renew https://support.fonio.app/. We may agree to align the
                    invoicing under multiple Orders, but this will not reduce the term of any Order. Terminating
                    specific Services does not affect the term of any other Services still in effect. If we permit you
                    to reinstate Services at any time after termination, you agree that you will be bound by the
                    then-current Terms and the renewal date that was in effect as of the effective termination date.
                </p>
                <p>
                    3.2. Termination for Cause. Either party may terminate the Agreement (i) if the other party breaches
                    its material obligations and fails to cure within 30 days of receipt of written notice, or (ii)
                    where permitted by applicable law, if the other party becomes insolvent or bankrupt, liquidated or
                    is dissolved, or ceases substantially all of its business, and we may suspend access or terminate
                    immediately if you breach Section 1.2, 4.1, 4.3, or 5.
                </p>
                <p>
                    3.3. Effect of Termination. If the Agreement or any Services are terminated, your account may be
                    converted to a “free” or “basic” version of the Service, if available, at our discretion. Otherwise,
                    you will immediately discontinue all use of the terminated Services, except that upon request, we
                    will provide you with limited access to the Services for a period not to exceed 30 days, solely to
                    enable you to retrieve your Content from the Services. We have no obligation to maintain your
                    Content after that period. To the extent permitted by applicable law, neither party will be liable
                    for any damages resulting from termination of the Agreement, and termination will not affect any
                    claim arising prior to the effective termination date. If we discontinue Services or materially
                    reduce the core functionality in accordance with Section 1.3 above, the related Order will be
                    terminated, and we will provide you with a pro rata refund of any prepaid, unused fees. You agree to
                    pay for any use of the Services past the date of expiration or termination which have not been
                    converted to a free version of the Service.
                </p>
                <p>3.4. Survival. The provisions of Sections 2 (Orders, Fees and Payment),</p>
                <p>
                    3.5 (Effect of Termination), 4 (Your Content and Accounts), 7 (Indemnification), 8 (Limitation on
                    Liability), 9.5 (No Class Actions), 9.10 (Notices), and 9.14 (Contracting Party, Choice of Law and
                    Location for Resolving Disputes) survive any termination of the Agreement.
                </p>
                <h3>4. YOUR CONTENT AND ACCOUNTS.</h3>
                <p>
                    4.1. Your Content. You retain all rights to your Content (defined below) and we do not own or
                    license your Content. We may use, modify, reproduce, and distribute your Content in order to provide
                    and operate the Services. You warrant that (i) you have the right to upload or otherwise share
                    Content with us, and (ii) your uploading or processing of your Content in the context of our
                    Services does not infringe on any rights of any third party. Each party agrees to apply reasonable
                    technical, organizational, and administrative security measures to keep Content protected in
                    accordance with industry standards. We will not view, access or process any of your Content, except:
                    (x) as authorized or instructed by you or your users in this Agreement or in any other agreement
                    between the parties, or (y) as required to comply with our policies, applicable law, or governmental
                    request. “Content” means any files, documents, recordings, chat logs, transcripts, and similar data
                    that we maintain on your or your users’ behalf, as well as any other information you or your users
                    may upload to your Service account in connection with the Services.
                </p>
                <p>
                    4.2. Your Privacy and Security. We maintain a global privacy and security program designed to
                    protect your Content and any associated personal data we may collect and/or process on your behalf.
                    You can visit our Privacy Policy (https://www.fonio.app/legal/privacy).
                </p>
                <p>
                    4.3. Your Accounts. You are solely responsible for (i) all use of the Services by you and your
                    users, (ii) maintaining lawful basis for the collection, use, processing and transfer of Content,
                    and (iii) providing notices or obtaining consent as legally required in connection with the
                    Services. We do not send emails asking for your usernames or passwords, and to keep your accounts
                    secure, you should keep all usernames and passwords confidential. We are not liable for any loss
                    that you may incur if a third party uses your password or account. We may suspend the Services or
                    terminate the Agreement if you, your users, or attendees are using the Services in a manner that is
                    likely to cause harm to us or if we have reasonable grounds for suspecting any illegal, fraudulent,
                    or abusive activity on your part. You agree to notify us immediately and terminate any unauthorized
                    access to the Services or other security breach.
                </p>
                <h3>5. COMPLIANCE WITH LAWS.</h3>
                <p>
                    In connection with the performance, access and use of the Services under the Agreement, each party
                    agrees to comply with all applicable laws, rules and regulations including, but not limited to
                    export, privacy, and data protection laws and regulations. Each party represents that it is not
                    named on any U.S. government denied-party list. Further, Customer shall not permit its users to
                    access or use any Service or Content in a U.S. embargoed country or in violation of any U.S. export
                    law or regulation. If necessary and in accordance with applicable law, we will cooperate with local,
                    state, federal and international government authorities with respect to the Services.
                    Notwithstanding any other provision in these Terms, we may immediately terminate the Agreement for
                    noncompliance with applicable laws.
                </p>
                <h3>6. WARRANTIES.</h3>
                <p>
                    WE WARRANT THAT THE SERVICES WILL CONFORM TO THE SERVICE DESCRIPTIONS UNDER NORMAL USE. WE DO NOT
                    REPRESENT OR WARRANT THAT (i) THE USE OF OUR SERVICES WILL BE TIMELY, UNINTERRUPTED OR ERROR FREE,
                    OR OPERATE IN COMBINATION WITH ANY SPECIFIC HARDWARE, SOFTWARE, SYSTEM OR DATA, (ii) OUR SERVICES
                    WILL MEET YOUR REQUIREMENTS, OR (iii) ALL ERRORS OR DEFECTS WILL BE CORRECTED. USE OF THE SERVICES
                    IS AT YOUR SOLE RISK. OUR ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY UNDER THIS WARRANTY WILL BE, AT
                    OUR SOLE OPTION AND SUBJECT TO APPLICABLE LAW, TO PROVIDE CONFORMING SERVICES, OR TO TERMINATE THE
                    NON-CONFORMING SERVICES OR THE APPLICABLE ORDER, AND PROVIDE A PRO-RATED REFUND OF ANY PREPAID FEES
                    FROM THE DATE YOU NOTIFY US OF THE NON-CONFORMANCE THROUGH THE END OF THE REMAINING TERM. TO THE
                    EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL OTHER WARRANTIES AND CONDITIONS, WHETHER
                    EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY EXPRESS OR IMPLIED WARRANTIES OF
                    MERCHANTABILITY, SATISFACTORY QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES AND CONDITIONS, THEREFORE SOME
                    OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO CUSTOMERS LOCATED IN THOSE JURISDICTIONS.
                </p>
                <h3>7. INDEMNIFICATION.</h3>
                <p>
                    You will indemnify and defend us against any third party claim resulting from a breach of Section
                    1.2, 4.1 or 4.3, or alleging that any of your Content infringes upon any patent or copyright, or
                    violates a trade secret of any party, and you agree to pay reasonable attorney’s fees, court costs,
                    damages finally awarded, or reasonable settlement costs with respect to any such claim. We will
                    promptly notify you of any claim and cooperate with you in defending the claim. You will reimburse
                    us for reasonable expenses incurred in providing any cooperation or assistance. You will have full
                    control and authority over the defense and settlement of any claim, except that: (i) any settlement
                    requiring us to admit liability requires prior written consent, not to be unreasonably withheld or
                    delayed, and (ii) we may join in the defense with our own counsel at our own expense.
                </p>
                <h3>8. LIMITATION ON LIABILITY.</h3>
                <p>
                    8.1. LIMITATION ON INDIRECT LIABILITY. NEITHER PARTY WILL BE LIABLE TO THE OTHER PARTY OR TO ANY
                    OTHER PERSON FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL LOSS, EXEMPLARY OR OTHER SUCH
                    DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES ARISING OUT OF OR RELATING TO: (i) LOSS OF DATA,
                    (ii) LOSS OF INCOME, (iii) LOSS OF OPPORTUNITY, (iv) LOST PROFITS, OR (v) COSTS OF RECOVERY, HOWEVER
                    CAUSED AND BASED ON ANY THEORY OF LIABILITY, INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), OR VIOLATION OF STATUTE, WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW LIMITATION OR EXCLUSION OF LIABILITY
                    FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY.
                </p>
                <p>
                    8.2. LIMITATION ON AMOUNT OF LIABILITY. EXCEPT FOR YOUR BREACH OF SECTIONS 1.2, 4.1, OR 4.3 AND YOUR
                    INDEMNIFICATION OBLIGATIONS, AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL CUMULATIVE
                    LIABILITY OF EITHER PARTY AND THEIR RESPECTIVE LICENSORS AND SUPPLIERS ARISING OUT OF THIS AGREEMENT
                    IS LIMITED TO THE SUM OF THE AMOUNTS PAID FOR THE APPLICABLE SERVICE DURING THE 12 MONTHS
                    IMMEDIATELY PRECEDING THE INCIDENT GIVING RISE TO THE LIABILITY. THE FOREGOING DOES NOT LIMIT YOUR
                    OBLIGATIONS TO PAY ANY UNDISPUTED FEES AND OTHER AMOUNTS DUE UNDER ANY ORDER.
                </p>
                <h3>9. ADDITIONAL TERMS.</h3>
                <p>
                    9.1. Free Services and Trials. Your right to access and use any free Services is not guaranteed for
                    any period of time and we reserve the right, in our sole discretion, to limit or terminate your use
                    of any free versions of any Services by any individual or entity. If you are using the Services on a
                    trial or promotional basis (“Trial Period”), your Trial Period and access to the Services will
                    terminate (i) at the end of the Trial Period stated in your Order, or (ii) if no date is specified,
                    30 days after your initial access to the Services, (iii) or upon your conversion to a subscription.
                    During the Trial Period, to the extent permitted by law, we provide the Services “AS IS” and without
                    warranty or indemnity, and all other terms otherwise apply. We may modify or discontinue any trials
                    or promotions at any time without notice.
                </p>
                <p>9.2. Third Party Services.</p>
                <p>
                    9.2.1. Services may provide the capability for you to link to or integrate with third party sites or
                    applications separately accessed by you and not purchased from us. We are not responsible for and do
                    not endorse such services. You have sole discretion whether to purchase or connect to any third
                    party services and your use is governed solely by the terms for those services.
                </p>
                <p>
                    9.2.2. Any third party services we have sold to you are subject to this Agreement, including any
                    additional terms specific to those services that may be set forth in the Service Descriptions.
                    Unless otherwise specified in the Service Descriptions, we and our contractors, suppliers, and
                    licensors disclaim all warranties, express or implied, and all liability for any third party
                    services we have sold to you.
                </p>
                <p>
                    9.3. Beta Services. We may offer you access to beta services that are being provided prior to
                    general release, but we do not make any guarantees that these services will be made generally
                    available (“Beta Services”). You understand and agree that the Beta Services may contain bugs,
                    errors, and other defects, and use of the Beta Services is at your sole risk. You acknowledge that
                    your use of Beta Services is on a voluntary and optional basis, and we have no obligation to provide
                    technical support and may discontinue provision of Beta Services at any time in our sole discretion
                    and without prior notice to you. These Beta Services are offered “AS-IS”,and to the extent permitted
                    by applicable law, we disclaim any liability, warranties, indemnities, and conditions, whether
                    express, implied, statutory, or otherwise. If you are using Beta Services, you agree to receive
                    related correspondence and updates from us and acknowledge that opting out may result in
                    cancellation of your access to the Beta Services. If you provide feedback (“Feedback”) about the
                    Beta Service, you agree that we own any Feedback that you share with us. For the Beta Services only,
                    these Terms supersede any conflicting terms and conditions in the Agreement, but only to the extent
                    necessary to resolve conflict.
                </p>
                <p>
                    9.4. Copyright. If you believe that our Services have been used in a way that constitutes copyright
                    infringement, you should contact our legal term at legal [at] fonio.app.
                </p>
                <p>
                    9.5. No Class Actions. You may only resolve disputes with us on an individual basis and you agree
                    not to bring or participate in any class, consolidated, or representative action against us or any
                    of our employees or affiliates.
                </p>
                <p>
                    9.6. Security Emergencies.If we reasonably determine that the security of our Services or
                    infrastructure may be compromised due to hacking attempts, denial of service attacks, or other
                    malicious activities, we may temporarily suspend the Services and we will take action to promptly
                    resolve any security issues. We will notify you of any suspension or other action taken for security
                    reasons.
                </p>
                <p>
                    9.7. High-Risk Use. You understand that the Services are not designed or intended for use during
                    high-risk activities which include, but are not limited to use in hazardous environments requiring
                    fail-safe controls, weapons systems, aircraft navigation, control, or communications systems, and/or
                    life support systems.
                </p>
                <p>
                    9.8. Recording. Certain Services provide functionality that allows you to record audio and data
                    shared during sessions. You are solely responsible for complying with all applicable laws in the
                    relevant jurisdictions while using recording functionality. We disclaim all liability for your
                    recording of audio or shared data, and you agree to hold us harmless from damages or liabilities
                    related to the recording of any audio or data.
                </p>
                <p>
                    9.9. Assignment. Neither party may assign its rights or delegate its duties under the Agreement
                    either in whole or in part without the other party’s prior written consent, which shall not be
                    unreasonably withheld, except that either party may assign the Agreement to an affiliated entity, or
                    as part of a corporate reorganization, consolidation, merger, acquisition, or sale of all or
                    substantially all of its business or assets to which this Agreement relates. Any attempted
                    assignment without consent will be void. The Agreement will bind and inure to the benefit of each
                    party’s successors or assigns.
                </p>
                <p>
                    9.10. Notices. Notices must be sent by personal delivery, overnight courier or registered or
                    certified mail. We may also provide notice to the email last designated on your account,
                    electronically via postings on our website, in-product notices, or our self-service portal or
                    administrative center. Unless specified elsewhere in this Agreement, notices should be sent to us at
                    the address for your applicable contracting entity, with a copy to our Legal Department, 333 Summer
                    Street, Boston, Massachusetts 02210 USA, and we will send notices to the address last designated on
                    your account. Notice is given (a) upon personal delivery; (b) for overnight courier, on the second
                    business day after notice is sent, (c) for registered or certified mail, on the fifth business day
                    after notice is sent, (d) for email, when the email is sent, or (e) if posted electronically, upon
                    posting.
                </p>
                <p>
                    9.12. Entire Agreement; Order of Precedence. The Agreement, including any applicable DPA, sets forth
                    the entire agreement between you and Fonio relating to the Services and supersedes all prior and
                    contemporaneous oral and written agreements, except as otherwise permitted. If there is a conflict
                    between an executed Order, a country-specific Regional Supplement, these Terms, the DPA, and the
                    Service Descriptions, in each case, as applicable, the conflict will be resolved in that order, but
                    only for the specific Services described in the applicable Order. Nothing contained in any document
                    submitted by you will add to or otherwise modify the Agreement. We may update the Terms from time to
                    time, which will be identified by the last updated date, and may be reviewed at Fonio website. Your
                    continued access to and use of the Services constitutes your acceptance of the then-current Terms.
                </p>
                <p>
                    9.13. General Terms. If any term of this Agreement is not enforceable, this will not affect any
                    other terms. Both parties are independent contractors and nothing in this Agreement creates a
                    partnership, agency, fiduciary or employment relationship between the parties. No person or entity
                    not a party to the Agreement will be a third party beneficiary. Our authorized distributors do not
                    have the right to modify the Agreement or to make commitments binding on us. Failure to enforce any
                    right under the Agreement will not waive that right. Unless otherwise specified, remedies are
                    cumulative. The Agreement may be agreed to online or executed by electronic signature and in one or
                    more counterparts. No party will be responsible for any delay or failure to perform under the
                    Agreement due to force majeure events (e.g. natural disasters; terrorist activities, activities of
                    third party service providers, labor disputes; and acts of government) and acts beyond a party’s
                    reasonable control, but only for so long as those conditions persist.
                </p>
                <p>
                    9.14. Contracting Party, Choice of Law and Location for Resolving Disputes. The Fonio contracting
                    entity, contact information, and governing law for your use of the Services will depend on where you
                    are and the specific Services you have ordered.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
</script>

<style></style>
