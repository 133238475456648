<template>
    <img :src="HamburgerLogo" alt="" class="navbar-icon-hamburger" v-on:click="showNavbar = !showNavbar" />
    <div class="navbar-block" v-show="!showNavbar">
        <div class="logo-img-navbar">
            <img :src="logo" alt="" />
        </div>
        <div class="navbar-block-closeIcon">
            <img :src="iconClose" alt="" v-on:click="showNavbar = !showNavbar" />
        </div>
        <div>
            <ul class="navbar-block-menu">
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#whyus_section" class="navbar-block-menu-link">
                        Why us
                    </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#pricing_section" class="navbar-block-menu-link">
                        Pricing
                    </a>
                </li>
                <li>
                    <a
                        v-on:click="showNavbar = !showNavbar"
                        href="#virtualphonesystem_section"
                        class="navbar-block-menu-link"
                    >
                        Virtual Phone System
                    </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#faq_section" class="navbar-block-menu-link">
                        FAQ
                    </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#ourservice_section" class="navbar-block-menu-link">
                        Our Services
                    </a>
                </li>
            </ul>
        </div>
        <div class="position-relative">
            <div class="d-flex justify-content-between navbar-btn-block">
                <router-link to="" v-on:click="showNavbar = !showNavbar" class="ghost-btn login mr-24">
                    Sign In
                </router-link>
                <router-link
                    to="https://portal.fonio.app"
                    v-on:click="showNavbar = !showNavbar"
                    class="indigo-btn"
                >
                    Get started
                </router-link>
            </div>
        </div>
    </div>
    <div v-on:click="showNavbar = !showNavbar" class="position-absolute black-bg" v-show="!showNavbar"></div>
</template>

<script>
export default {
    data() {
        return {
            showNavbar: true,
            iconClose: require('../../assets/images/components/Close-icon.svg'),
            HamburgerLogo: require('../../assets/images/components/Hamburger-Black.svg'),
            logo: require('./../../assets/images/fonio-logo.svg'),
        }
    },
}
</script>

<style>
@import '../../assets/styles/components/Navbar.css';
</style>
