<template>
    <FooterMobile />

    <footer class="hide-mobile show-tablet">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 pb-t-40">
                    <a href="https://fonio.app/">
                        <img :src="logo" alt="LOGO" />
                    </a>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4">
                    <div class="menuTitle">Company</div>
                    <ul>
                        <li>
                            <a href="#whyus_section">Why us</a>
                        </li>
                        <li>
                            <a href="#pricing_section">Pricing</a>
                        </li>
                        <li>
                            <a href="#virtualphonesystem_section">Virtual Phone System</a>
                        </li>
                        <li>
                            <a href="#faq_section">FAQ</a>
                        </li>
                        <li>
                            <a href="#ourservice_section">Our Services</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4">
                    <div class="menuTitle">Legal</div>
                    <ul>
                        <li>
                            <router-link to="/PrivacyPolicyPage"> Privacy Policy </router-link>
                        </li>
                        <li>
                            <router-link to="/TermsOfUsePage"> Terms of Use </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4">
                    <div class="menuTitle">Contact</div>
                    <ul>
                        <li>Peering Hub Inc.</li>

                      <li class="phone-link">(484) 424-9683</li>
                        <li>support@fonio.app</li>
                        <li>823 Pilot Road, Las Vegas, NV, 89119</li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 pb-t-40 d-flex-t justify-content-between">
                    <div class="menuTitle readytoJoinText">Ready to Join?</div>
                    <div class="d-flex flex-row flex-lg-column justify-content-between">
                        <div class="mb-lg-4 mr-lg-0 mr-3">
                            <a href="https://portal.fonio.app" class="indigo-btn w-100">Get Started</a>
                        </div>
                        <div>
                            <a href="#contact_section" class="ghost-btn w-100">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="d-flex justify-content-between">
                    <div>© {{ currentYear }} Fonio. All rights reserved.</div>
                    <div>
                        <!-- <a href="#">Twitter</a>
                        <a href="#">LinkedIn</a> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import FooterMobile from '../../components/layouts/FooterMobile.vue'

export default {
    data() {
        return {
            logo: require('./../../assets/images/fonio-logo.svg'),
        }
    },
    components: {
        FooterMobile,
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    },
    methods: {},
}
</script>
<style>
@import './../assets/styles/layouts/footer.css';
</style>
