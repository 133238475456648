<template>
    <header>
        <div class="container">
            <div class="position-relative">
                <Navbar />

                <div class="row align-items-center">
                    <div class="col-md-4">
                        <a href="https://fonio.app/">
                            <img :src="logo" class="logo-img" alt="LOGO" />
                        </a>
                    </div>

                    <div class="col-md-6 hide-mobile">
                        <ul>
                            <li>
                                <a href="#whyus_section">Why us</a>
                            </li>
                            <li>
                                <a href="#pricing_section">Pricing</a>
                            </li>
                            <li>
                                <a href="#virtualphonesystem_section">Virtual Phone System</a>
                            </li>
                            <li class="hide-menu-custom-1240">
                                <a href="#faq_section">FAQ</a>
                            </li>
                            <li class="hide-menu-custom-1440">
                                <a href="#ourservice_section">Our Services</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 hide-mobile">
                        <a href="https://portal.fonio.app" class="ghost-btn small--btn">Sign in</a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Navbar from './Navbar.vue'

export default {
    components: {
        Navbar,
    },
    data() {
        return {
            logo: require('./../../assets/images/fonio-logo.svg'),
        }
    },
    methods: {},
}
</script>

<style scoped>
@import './../assets/styles/layouts/header.css';
.ghost-btn {
    color: #6366F1;
}
</style>
