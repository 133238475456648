<template>
    <div v-on:click="setItem()" class="accordion">
        <div :class="accordionShow ? 'accordion-upArrow' : 'accordion-downArrow'"></div>
        <span class="accordion-header">{{ this.textQuestion }}</span>
        <div v-if="accordionShow" class="accordion-body">
            {{ this.textDescription }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['accordionNumberCurrent', 'accordionNumber', 'textQuestion', 'textDescription'],
    methods: {
        setItem() {
            if (this.accordionNumber == this.accordionNumberCurrent) {
                this.accordionShow = !this.accordionShow
            } else {
                this.accordionShow = false
            }
        },
    },
    data() {
        return {
            accordionShow: false,
        }
    },
}
</script>
<style>
@import './../assets/styles/components/Accordion.css';
</style>
